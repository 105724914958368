import React from "react";
import Fade from "@mui/material/Fade";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Delete from "@mui/icons-material/Delete";
import {
  useFieldArray,
  Controller,
  useFormState,
  useFormContext,
} from "react-hook-form";
import TabButton from "./TabButton";
import { INotes } from "../../data/Notes";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "2-digit",
  month: "numeric",
  day: "numeric",
};

interface INotesFormProps {
  disabled?: boolean;
  parentLoading?: boolean;
}

export default function NotesForm({
  disabled,
  parentLoading,
}: INotesFormProps) {
  const { control, register } = useFormContext<INotes>();
  const { errors } = useFormState({
    control,
  });
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "notes",
    keyName: "key",
  });
  const loading = parentLoading ?? false;
  const addNote = () =>
    prepend(
      { text: "", date: new Date() },
      { shouldFocus: true, focusName: "notes.0.text" }
    );

  return (
    <>
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        sx={{ borderBottom: 1, borderColor: "grey.300" }}
      >
        <Tab label="Notes" />
        <TabButton onClick={addNote}>Add</TabButton>
      </Tabs>
      <Fade in={!loading} style={{ transitionDelay: "300ms" }}>
        <Timeline position="right" sx={{ p: 0, mt: "2px" }}>
          {fields.map((item, index) => (
            <TimelineItem key={item.key}>
              <TimelineOppositeContent sx={{ flex: `0 0 56px`, pl: 1, pr: 1 }}>
                <Typography variant="caption" color="textSecondary">
                  {new Date(item.date).toLocaleDateString("en-GB", dateOptions)}
                </Typography>
                <IconButton aria-label="delete" onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot variant="outlined" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ pr: 0 }}>
                {item.id != null ? (
                  <input
                    type="hidden"
                    {...register(`notes.${index}.id` as const)}
                    defaultValue={item.id}
                  />
                ) : null}
                <Controller
                  name={`notes.${index}.text`}
                  control={control}
                  defaultValue={item.text}
                  render={({ field }) => (
                    <TextField
                      label="Note"
                      variant="filled"
                      margin="dense"
                      disabled={disabled}
                      multiline
                      fullWidth
                      onChange={field.onChange}
                      value={field.value}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      error={!!errors?.notes?.[index]?.text}
                      helperText={errors?.notes?.[index]?.text?.message}
                    />
                  )}
                />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Fade>
    </>
  );
}
