import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import CheckBoxOutlineBlankOutlined from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import FilterList from "@mui/icons-material/FilterList";
import FolderOutlined from "@mui/icons-material/FolderOutlined";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  ICandidateListItem,
  salaryFormatter,
  useCandidates,
} from "../../data/Candidates";
import CandidateListFilter from "./CandidateListFilter";

type Order = "asc" | "desc";

export interface ICandidateFilter {
  search: string;
  nationality: number | null;
  sectors: number[];
  technical_skills: number[];
  levels: number[];
  languages: number[];
  areas: number[];
  driving_licence: number | null;
  max_notice_period: number;
  status: number | null;
  min_salary: string;
  max_salary: string;
  permanent_resident: boolean;
  qualifies_for_bee: boolean;
  valid_work_permit: boolean;
}

const defaultValues: ICandidateFilter = {
  search: "",
  nationality: null,
  sectors: [],
  technical_skills: [],
  levels: [],
  languages: [],
  areas: [],
  driving_licence: null,
  max_notice_period: 12,
  status: null,
  min_salary: "",
  max_salary: "",
  permanent_resident: false,
  qualifies_for_bee: false,
  valid_work_permit: false,
};

function parseSearchParams(query: URLSearchParams) {
  const search = query.get("search");
  const nationality = query.get("nationality");
  const sectors = query.get("sectors");
  const technicalSkills = query.get("technical_skills");
  const levels = query.get("levels");
  const languages = query.get("languages");
  const areas = query.get("areas");
  const drivingLicence = query.get("driving_licence");
  const maximumNoticePeriod = Number(query.get("max_notice_period") ?? 12);
  const status = query.get("status");
  const salaryMin = query.get("min_salary");
  const salaryMax = query.get("max_salary");
  const permanent_resident = query.get("permanent_resident");
  const qualifies_for_bee = query.get("qualifies_for_bee");
  const valid_work_permit = query.get("valid_work_permit");
  return {
    search: search ?? "",
    nationality: nationality ? Number(nationality) : null,
    sectors: sectors ? sectors.split(",").map(Number) : [],
    technical_skills: technicalSkills
      ? technicalSkills.split(",").map(Number)
      : [],
    levels: levels ? levels.split(",").map(Number) : [],
    languages: languages ? languages.split(",").map(Number) : [],
    areas: areas ? areas.split(",").map(Number) : [],
    driving_licence: drivingLicence ? Number(drivingLicence) : null,
    max_notice_period: Number.isNaN(maximumNoticePeriod)
      ? 12
      : maximumNoticePeriod,
    status: status ? Number(status) : null,
    min_salary: salaryMin ?? "",
    max_salary: salaryMax ?? "",
    permanent_resident: permanent_resident === "true",
    qualifies_for_bee: qualifies_for_bee === "true",
    valid_work_permit: valid_work_permit === "true",
  };
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

export default function CandidateList() {
  const { control, handleSubmit, reset } = useForm<ICandidateFilter>({
    defaultValues: defaultValues,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    document.title = "Candidates";
  }, []);

  const [candidates, candidatesLoading] = useCandidates(
    searchParams.toString()
  );

  const pageSize = Number(searchParams.get("page_size") ?? 10);
  const page = Math.max(Number(searchParams.get("page")) - 1, 0);
  const ordering = searchParams.get("ordering") || "-modified";
  const order: Order = ordering[0] === "-" ? "desc" : "asc";
  const orderBy: string =
    ordering[0] === "-" ? ordering.substring(1) : ordering;

  React.useEffect(() => {
    const params = parseSearchParams(searchParams);
    reset(params);
  }, [searchParams, reset]);

  const createSortHandler = (property: string) => () => {
    const isAsc = orderBy === property && order === "asc";
    let query = new URLSearchParams(searchParams);
    query.set("ordering", isAsc ? `-${property}` : property);
    setSearchParams(query);
  };

  const handlePageChange = (event: any, newPage: number) => {
    let query = new URLSearchParams(searchParams);
    query.set("page", (newPage + 1).toString());
    setSearchParams(query);
  };

  const handlePageSizeChange = (event: any) => {
    const value = parseInt(event.target.value, 10);
    let query = new URLSearchParams(searchParams);
    query.set("page_size", value.toString());
    query.set("page", "1");
    setSearchParams(query);
  };

  const handleFilter = (data: ICandidateFilter) => {
    let query = new URLSearchParams(searchParams);
    query.delete("search");
    if (data.search) query.set("search", data.search);
    query.delete("nationality");
    if (data.nationality) query.set("nationality", data.nationality.toString());
    query.delete("sectors");
    if (data.sectors.length) query.set("sectors", data.sectors.toString());
    query.delete("technical_skills");
    if (data.technical_skills.length)
      query.set("technical_skills", data.technical_skills.toString());
    query.delete("levels");
    if (data.levels.length) query.set("levels", data.levels.toString());
    query.delete("languages");
    if (data.languages.length)
      query.set("languages", data.languages.toString());
    query.delete("areas");
    if (data.areas.length) query.set("areas", data.areas.toString());
    query.delete("driving_licence");
    if (data.driving_licence)
      query.set("driving_licence", data.driving_licence.toString());
    query.delete("max_notice_period");
    if (data.max_notice_period) {
      query.set("max_notice_period", data.max_notice_period.toString());
    }
    query.delete("status");
    if (data.status) query.set("status", data.status.toString());
    query.delete("min_salary");
    if (data.min_salary) query.set("min_salary", data.min_salary);
    query.delete("max_salary");
    if (data.max_salary) query.set("max_salary", data.max_salary);
    query.delete("permanent_resident");
    if (data.permanent_resident) query.set("permanent_resident", "true");
    query.delete("qualifies_for_bee");
    if (data.qualifies_for_bee) query.set("qualifies_for_bee", "true");
    query.delete("valid_work_permit");
    if (data.valid_work_permit) query.set("valid_work_permit", "true");

    query.delete("page");
    setSearchParams(query);
  };

  const emptyRows = pageSize - (candidates?.results?.length ?? 0);

  return (
    <Box
      sx={{
        display: "grid",
        borderBottom: 1,
        borderColor: "grey.300",
        gridTemplateColumns: `1fr 320px`,
        gridTemplateAreas: `
      "table filter"
      `,
      }}
    >
      <Box sx={{ gridArea: "table" }}>
        <Toolbar sx={{ borderBottom: 1, borderColor: "grey.300" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Candidates
          </Typography>
          <Stack direction="row" spacing={1}>
            <Tooltip title="Add">
              <IconButton component={RouterLink} to="/candidates/create">
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter">
              <IconButton>
                <FilterList />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
        {candidatesLoading ? <LinearProgress sx={{ mt: -0.5 }} /> : null}
        <TableContainer component="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="110px" />
                <TableCell
                  align="left"
                  width="20%"
                  sortDirection={orderBy === "modified" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "modified"}
                    direction={orderBy === "modified" ? order : "asc"}
                    onClick={createSortHandler("modified")}
                  >
                    Last Edited
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  // width="50%"
                  sortDirection={orderBy === "name" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={createSortHandler("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" width="20%">
                  Nationality
                </TableCell>
                <TableCell
                  align="left"
                  width="20%"
                  sortDirection={orderBy === "salary" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "salary"}
                    direction={orderBy === "salary" ? order : "asc"}
                    onClick={createSortHandler("salary")}
                  >
                    Salary
                  </TableSortLabel>
                </TableCell>
                <TableCell key="actions" align="right" width="90px" />
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates?.results.map((candidate) => (
                <CandidateRow key={candidate.id} candidate={candidate} />
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: `${67 * emptyRows}px` }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {candidatesLoading ? <LinearProgress sx={{ mt: -0.5 }} /> : null}
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={candidates?.count ?? 0}
          rowsPerPage={pageSize}
          page={candidates?.count ? page : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
        />
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(handleFilter)}
        autoComplete="off"
        sx={{ gridArea: "filter", borderLeft: 1, borderColor: "grey.300" }}
      >
        <CandidateListFilter control={control} />
      </Box>
    </Box>
  );
}

interface CandidateRowProps {
  candidate: ICandidateListItem;
}

function CandidateRow({ candidate }: CandidateRowProps) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState<ICandidateFilter>(defaultValues);

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const params = parseSearchParams(searchParams);
    setFilter(params);
  }, [searchParams]);

  return (
    <>
      <TableRow
        tabIndex={-1}
        className={
          candidate.status === "Placed" ? "candidate-placed" : undefined
        }
        sx={{
          ...{ "& > *": { borderBottom: "unset !important" } },
          ...(candidate.status === "Placed"
            ? { outline: "1.5px solid red", outlineOffset: "-1.5px" }
            : {}),
        }}
      >
        <TableCell scope="row" align="left">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell scope="row" align="left">
          {new Date(candidate.modified).toLocaleDateString(
            "en-GB",
            dateOptions
          )}
        </TableCell>
        <TableCell scope="row" align="left">
          {candidate.name}
        </TableCell>
        <TableCell scope="row" align="left">
          {candidate.nationality}
        </TableCell>
        <TableCell scope="row" align="left">
          {salaryFormatter.format(candidate.salary)}
        </TableCell>
        <TableCell align="right" padding="none">
          <IconButton
            href={candidate.folder ?? ""}
            target="_blank"
            rel="noreferrer noopener"
          >
            <FolderOutlined />
          </IconButton>
          <IconButton
            component={RouterLink}
            to={`/candidates/${candidate.id}/`}
          >
            <EditOutlined />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ pb: 0, pt: 0, backgroundColor: "grey.100" }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ display: "flex", margin: 1 }}>
              <Box sx={{ flex: "2 1 0" }}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell width="40%">Email</TableCell>
                      <TableCell align="left">{candidate.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="45%">Status</TableCell>
                      <TableCell align="left">{candidate.status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="45%">Driving Licence</TableCell>
                      <TableCell align="left">
                        {candidate.driving_licence}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="45%">Created</TableCell>
                      <TableCell align="left">
                        {new Date(candidate.created).toLocaleDateString(
                          "en-GB",
                          dateOptions
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="45%">Valid Work Permit</TableCell>
                      <TableCell>
                        {candidate.valid_work_permit ? (
                          <CheckBoxOutlined fontSize="small" />
                        ) : (
                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="45%">BEE Qualified</TableCell>
                      <TableCell>
                        {candidate.qualifies_for_bee ? (
                          <CheckBoxOutlined fontSize="small" />
                        ) : (
                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="45%">Permanent Resident</TableCell>
                      <TableCell>
                        {candidate.permanent_resident ? (
                          <CheckBoxOutlined fontSize="small" />
                        ) : (
                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{ flex: "4 1 0", ml: 2 }}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell width="120px">Sectors</TableCell>
                      <TableCell align="left">
                        {candidate.sectors.map((sector) => (
                          <Chip
                            key={sector.id}
                            color={
                              filter.sectors.includes(sector.id)
                                ? "info"
                                : "default"
                            }
                            size="small"
                            label={sector.name}
                            sx={{ m: "1px" }}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="120px">Skills</TableCell>
                      <TableCell align="left">
                        {candidate.technical_skills.map((skill) => (
                          <Chip
                            key={skill.id}
                            color={
                              filter.technical_skills.includes(skill.id)
                                ? "info"
                                : "default"
                            }
                            size="small"
                            label={skill.name}
                            sx={{ m: "1px" }}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="120px">Levels</TableCell>
                      <TableCell align="left">
                        {candidate.levels.map((level) => (
                          <Chip
                            key={level.id}
                            color={
                              filter.levels.includes(level.id)
                                ? "info"
                                : "default"
                            }
                            size="small"
                            label={level.name}
                            sx={{ m: "1px" }}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="120px">Areas</TableCell>
                      <TableCell align="left">
                        {candidate.areas.map((area) => (
                          <Chip
                            key={area.id}
                            color={
                              filter.areas.includes(area.id)
                                ? "info"
                                : "default"
                            }
                            size="small"
                            label={area.name}
                            sx={{ m: "1px" }}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="120px">Languages</TableCell>
                      <TableCell align="left">
                        {candidate.languages.map((language) => (
                          <Chip
                            key={language.id}
                            color={
                              filter.languages.includes(language.id)
                                ? "info"
                                : "default"
                            }
                            size="small"
                            label={language.name}
                            sx={{ m: "1px" }}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="120px">Qualifications</TableCell>
                      <TableCell align="left">
                        {candidate.qualifications.map((qualification) => (
                          <Chip
                            key={qualification.id}
                            size="small"
                            label={qualification.name}
                            sx={{ m: "1px" }}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
