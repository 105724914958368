import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FolderIcon from "@mui/icons-material/Folder";
import DialogContentText from "@mui/material/DialogContentText";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClientDetailsForm from "./ClientDetailsForm";
import ClientContactForm from "./ClientContactForm";
import { clientSchema, IClient, useClient } from "../../data/Clients";
import LinearProgress from "@mui/material/LinearProgress";
import NotesForm from "../misc/NotesForm";

import { useSnackbar } from "notistack";

const defaultValues: IClient = {
  name: "",
  sectors: [],
  notes: [],
  contacts: [],
};

enum SubmissionStatus {
  Unsubmitted = "unsubmitted",
  Submitting = "submitting",
  Completed = "competed",
}

export default function ClientUpdate() {
  const methods = useForm<IClient>({
    defaultValues: defaultValues,
    resolver: yupResolver(clientSchema),
  });
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [client, setClient, clientLoading] = useClient(id);

  const [submissionStatus, setSubmissionStatus] = React.useState(
    SubmissionStatus.Unsubmitted
  );

  const submitting = submissionStatus === SubmissionStatus.Submitting;

  const [dialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!clientLoading) {
      reset(client!);
      document.title = client?.name || "Clients";
    }
  }, [id, clientLoading, client, reset]);

  React.useEffect(() => {
    if (isSubmitSuccessful && submissionStatus === SubmissionStatus.Completed) {
      setSubmissionStatus(SubmissionStatus.Unsubmitted);
      reset(client!);
    }
  }, [isSubmitSuccessful, submissionStatus, client, reset]);

  const handleUpdate: SubmitHandler<IClient> = (data) => {
    setSubmissionStatus(SubmissionStatus.Submitting);
    axios
      .patch<IClient>(`/clients/${id}/`, data)
      .then((response) => {
        setClient(response.data);
        setSubmissionStatus(SubmissionStatus.Completed);
        enqueueSnackbar(`${response.data.name} updated.`, {
          variant: "success",
        });
      })
      .catch((error) => {
        setSubmissionStatus(SubmissionStatus.Unsubmitted);
        console.log(error);
      });
  };

  const handleDelete = () => {
    setSubmissionStatus(SubmissionStatus.Submitting);
    axios
      .delete(`/clients/${id}`)
      .then(() => {
        setSubmissionStatus(SubmissionStatus.Completed);
        enqueueSnackbar("Client deleted.", { variant: "warning" });
        setDialogOpen(false);
        navigate("/clients");
      })
      .catch((error) => {
        setSubmissionStatus(SubmissionStatus.Unsubmitted);
        setDialogOpen(false);
        console.log(error);
      });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleUpdate)}
        autoComplete="off"
        sx={{
          display: "grid",
          columnGap: 3,
          ml: 3,
          mr: 3,
          gridTemplateRows: `auto 1fr`,
          gridTemplateColumns: `1fr 1fr 1fr 1fr`,
          gridTemplateAreas: `
                "toolbar toolbar toolbar toolbar"
                "client contacts notes _"
                `,
        }}
      >
        <Box
          sx={{
            gridArea: "toolbar",
            borderBottom: 1,
            borderTop: 1,
            borderColor: "grey.300",
          }}
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              CLIENT
            </Typography>
            <Stack spacing={2} direction="row">
              {!clientLoading && client?.folder ? (
                <Button
                  href={client?.folder!}
                  target="_blank"
                  rel="noreferrer noopener"
                  startIcon={<FolderIcon />}
                >
                  Folder
                </Button>
              ) : null}
              <LoadingButton
                onClick={handleDialogOpen}
                startIcon={<DeleteIcon />}
                loading={submitting}
                loadingPosition="start"
                variant="text"
              >
                Delete
              </LoadingButton>
              <LoadingButton
                type="submit"
                startIcon={<SaveIcon />}
                loading={submitting}
                loadingPosition="start"
                variant="contained"
              >
                Update
              </LoadingButton>
            </Stack>
          </Toolbar>
          {clientLoading ? <LinearProgress sx={{ mt: -0.5 }} /> : null}
        </Box>
        <Box sx={{ gridArea: "client" }}>
          <ClientDetailsForm
            control={control}
            disabled={submitting}
            clientLoading={clientLoading}
          />
        </Box>
        <Box sx={{ gridArea: "contacts" }}>
          <ClientContactForm
            control={control}
            register={register}
            disabled={submitting}
            clientLoading={clientLoading}
          />
        </Box>
        <Box sx={{ gridArea: "notes" }}>
          <NotesForm disabled={submitting} parentLoading={clientLoading} />
        </Box>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Delete this client?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              onClick={handleDialogClose}
              loading={submitting}
              variant="text"
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
              loading={submitting}
              loadingPosition="start"
              variant="text"
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    </FormProvider>
  );
}
