import React from "react";
import axios from "axios";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import ClientDetailsForm from "./ClientDetailsForm";
import ClientContactForm from "./ClientContactForm";
import { clientSchema, IClient } from "../../data/Clients";
import NotesForm from "../misc/NotesForm";

const defaultValues: IClient = {
  name: "",
  sectors: [],
  notes: [],
  contacts: [],
};

enum SubmissionStatus {
  Unsubmitted = "unsubmitted",
  Submitting = "submitting",
  Completed = "competed",
}

export default function ClientCreate() {
  const methods = useForm<IClient>({
    defaultValues: defaultValues,
    resolver: yupResolver(clientSchema),
  });
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  React.useEffect(() => {
    document.title = "Clients";
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const [submissionStatus, setSubmissionStatus] = React.useState(
    SubmissionStatus.Unsubmitted
  );

  const submitting = submissionStatus === SubmissionStatus.Submitting;

  React.useEffect(() => {
    if (isSubmitSuccessful && submissionStatus === SubmissionStatus.Completed) {
      setSubmissionStatus(SubmissionStatus.Unsubmitted);
      reset(defaultValues);
    }
  }, [isSubmitSuccessful, submissionStatus, reset]);

  const handleCreate: SubmitHandler<IClient> = (data) => {
    setSubmissionStatus(SubmissionStatus.Submitting);
    axios
      .post<IClient>("/clients/", data)
      .then((response) => {
        setSubmissionStatus(SubmissionStatus.Completed);
        enqueueSnackbar(`${response.data.name} created.`, {
          action: (
            <IconButton
              component={RouterLink}
              to={`/clients/${response.data.id}/`}
            >
              <EditIcon sx={{ color: "white" }} />
            </IconButton>
          ),
          variant: "success",
        });
      })
      .catch((error) => {
        setSubmissionStatus(SubmissionStatus.Unsubmitted);
        console.log(error);
      });
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleCreate)}
        autoComplete="off"
        sx={{
          display: "grid",
          columnGap: 3,
          ml: 3,
          mr: 3,
          gridTemplateRows: `auto 1fr`,
          gridTemplateColumns: `1fr 1fr 1fr 1fr`,
          gridTemplateAreas: `
                  "toolbar toolbar toolbar toolbar"
                  "client contacts notes _"
                  `,
        }}
      >
        <Box
          sx={{
            gridArea: "toolbar",
            borderBottom: 1,
            borderTop: 1,
            borderColor: "grey.300",
          }}
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              CLIENT
            </Typography>
            <LoadingButton
              type="submit"
              startIcon={<SaveIcon />}
              loading={submitting}
              loadingPosition="start"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Toolbar>
        </Box>
        <Box sx={{ gridArea: "client" }}>
          <ClientDetailsForm control={control} disabled={submitting} />
        </Box>
        <Box sx={{ gridArea: "contacts" }}>
          <ClientContactForm
            control={control}
            register={register}
            disabled={submitting}
          />
        </Box>
        <Box sx={{ gridArea: "notes" }}>
          <NotesForm disabled={submitting} />
        </Box>
      </Box>
    </FormProvider>
  );
}
