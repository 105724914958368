import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import EditOutlined from "@mui/icons-material/EditOutlined";
import FilterList from "@mui/icons-material/FilterList";
import FolderOutlined from "@mui/icons-material/FolderOutlined";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useClients } from "../../data/Clients";
import Filter from "./ClientListFilter";
import LinearProgress from "@mui/material/LinearProgress";

type Order = "asc" | "desc";

export interface IClientFilter {
  search: string;
  sectors: number[];
}

const defaultValues: IClientFilter = {
  search: "",
  sectors: [],
};

function parseSearchParams(query: URLSearchParams) {
  const search = query.get("search");
  const sectors = query.get("sectors");
  return {
    search: search ?? "",
    sectors: sectors ? sectors.split(",").map(Number) : [],
  };
}

export default function ClientList() {
  const { control, handleSubmit, reset } = useForm<IClientFilter>({
    defaultValues: defaultValues,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    document.title = "Clients";
  }, []);

  const [clients, clientsLoading] = useClients(searchParams.toString());

  React.useEffect(() => {
    const params = parseSearchParams(searchParams);
    reset(params);
  }, [searchParams, reset]);

  const pageSize = Number(searchParams.get("page_size") ?? 10);
  const page = Math.max(Number(searchParams.get("page")) - 1, 0);
  const ordering = searchParams.get("ordering") || "-modified";
  const order: Order = ordering[0] === "-" ? "desc" : "asc";
  const orderBy: string =
    ordering[0] === "-" ? ordering.substring(1) : ordering;

  const createSortHandler = (property: string) => () => {
    const isAsc = orderBy === property && order === "asc";
    let query = new URLSearchParams(searchParams);
    query.set("ordering", isAsc ? `-${property}` : property);
    setSearchParams(query);
  };

  const handlePageChange = (event: any, newPage: number) => {
    let query = new URLSearchParams(searchParams);
    query.set("page", (newPage + 1).toString());
    setSearchParams(query);
  };

  const handlePageSizeChange = (event: any) => {
    const value = parseInt(event.target.value, 10);
    let query = new URLSearchParams(searchParams);
    query.set("page_size", value.toString());
    query.set("page", "1");
    setSearchParams(query);
  };

  const handleFilter = (data: IClientFilter) => {
    let query = new URLSearchParams(searchParams);
    query.delete("search");
    if (data.search) query.set("search", data.search);
    query.delete("sectors");
    if (data.sectors.length) query.set("sectors", data.sectors.toString());

    query.delete("page");
    setSearchParams(query);
  };

  const emptyRows = pageSize - (clients?.results?.length ?? 0);

  return (
    <Box
      sx={{
        display: "grid",
        borderBottom: 1,
        borderColor: "grey.300",
        gridTemplateColumns: `1fr 320px`,
        gridTemplateAreas: `
        "table filter"
        `,
      }}
    >
      <Box sx={{ gridArea: "table" }}>
        <Toolbar sx={{ borderBottom: 1, borderColor: "grey.300" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Clients
          </Typography>
          <Stack direction="row" spacing={1}>
            <Tooltip title="Add">
              <IconButton component={RouterLink} to="/clients/create">
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter">
              <IconButton>
                <FilterList />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
        {clientsLoading ? <LinearProgress sx={{ mt: -0.5 }} /> : null}
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell
                  width="30%"
                  align="left"
                  sortDirection={orderBy === "name" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={createSortHandler("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell width="60%" align="left">
                  Sectors
                </TableCell>
                <TableCell width="10%" key="actions" align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {clients?.results.map((client) => (
                <TableRow hover tabIndex={-1} key={client.name}>
                  <TableCell scope="row" width="30%">
                    {client.name}
                  </TableCell>
                  <TableCell align="left" width="60%">
                    {client.sectors.map((sector) => (
                      <Chip
                        key={sector}
                        size="small"
                        label={sector}
                        sx={{ m: "1px" }}
                      />
                    ))}
                  </TableCell>
                  <TableCell align="right" padding="none" width="10%">
                    <IconButton
                      href={client.folder}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <FolderOutlined />
                    </IconButton>
                    <IconButton
                      component={RouterLink}
                      to={`/clients/${client.id}/`}
                    >
                      <EditOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 59 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {clientsLoading ? <LinearProgress sx={{ mt: -0.5 }} /> : null}
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={clients?.count || -1}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
        />
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(handleFilter)}
        autoComplete="off"
        sx={{ gridArea: "filter", borderLeft: 1, borderColor: "grey.300" }}
      >
        <Filter control={control} />
      </Box>
    </Box>
  );
}
