import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grow from "@mui/material/Grow";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PeopleOutlined from "@mui/icons-material/PeopleOutlined";
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CookiesProvider, useCookies } from "react-cookie";
import { SnackbarProvider } from "notistack";
import axios from "axios";

import CandidateList from "./components/candidate/CandidateListPage";
import ClientList from "./components/client/ClientListPage";
import ClientCreate from "./components/client/ClientCreatePage";
import CandidateCreate from "./components/candidate/CandidateCreatePage";
import ListItemButtonLink from "./components/misc/ListItemButtonLink";
import Login from "./components/login/LoginPage";
import "./AxiosConfig";
import { msalConfig } from "./AuthConfig";
import CandidateUpdate from "./components/candidate/CandidateUpdatePage";
import ClientUpdate from "./components/client/ClientUpdatePage";
import NameFastFind from "./components/misc/NameFastFind";

const pca = new PublicClientApplication(msalConfig);

export default function App() {
  return (
    <>
      <CssBaseline />
      <CookiesProvider>
        <MsalProvider instance={pca}>
          <BrowserRouter>
            <SnackbarProvider
              TransitionComponent={Grow as any}
              autoHideDuration={10000}
              hideIconVariant
            >
              <Routes>
                <Route path="/" element={<AppShell />}>
                  <Route path="candidates" element={<CandidateList />} />
                  <Route path="candidates/:id" element={<CandidateUpdate />} />
                  <Route
                    path="candidates/create"
                    element={<CandidateCreate />}
                  />
                  <Route path="clients" element={<ClientList />} />
                  <Route path="clients/:id" element={<ClientUpdate />} />
                  <Route path="clients/create" element={<ClientCreate />} />
                </Route>
                <Route path="/login" element={<Login />} />
              </Routes>
            </SnackbarProvider>
          </BrowserRouter>
        </MsalProvider>
      </CookiesProvider>
    </>
  );
}

function AppShell() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies();

  const handleLogout = () => {
    axios
      .get("/logout/")
      .then(() => {
        navigate("/login/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!cookies.identity) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "grid",
        gridTemplateColumns: "73px 1fr",
        gridTemplateRows: "auto 1fr",
        gridTemplateAreas: `
                          "header header"
                          "nav main"
                          `,
      }}
    >
      <Box sx={{ gridArea: "header" }}>
        <AppBar position="relative" elevation={0}>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              Action Appointments
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <NameFastFind />
            </Box>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          gridArea: "nav",
          borderRight: 1,
          borderColor: "grey.300",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <List>
          <ListItemButtonLink
            icon={<WorkOutlineOutlined />}
            primary="Clients"
            to="/clients"
          />
          <ListItemButtonLink
            icon={<PeopleOutlined />}
            primary="Candidates"
            to="/candidates"
          />
        </List>
      </Box>
      <Box sx={{ gridArea: "main", overflowY: "scroll" }}>
        <Outlet />
      </Box>
    </Box>
  );
}
