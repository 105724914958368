import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FolderIcon from "@mui/icons-material/Folder";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  candidateSchema,
  ICandidate,
  useCandidate,
} from "../../data/Candidates";
import CandidateContactForm from "./CandidateContactForm";
import CandidateBioForm from "./CandidateBioForm";
import NotesForm from "../misc/NotesForm";
import CandidateOfficeForm from "./CandidateOfficeForm";
import LinearProgress from "@mui/material/LinearProgress";
import { useSnackbar } from "notistack";

const defaultValues: ICandidate = {
  name: "",
  title: null,
  status: "",
  id_number: "",
  phone_number_primary: "",
  phone_number_secondary: "",
  email: "",
  email_secondary: "",
  address: "",
  notice_period: 0,
  gender: null,
  qualifies_for_bee: false,
  valid_work_permit: false,
  permanent_resident: false,
  has_own_car: false,
  salary: "",
  nationality: null,
  driving_licence: null,
  areas: [],
  languages: [],
  qualifications: [],
  notes: [],
  sectors: [],
  technical_skills: [],
  levels: [],
  created_by: "",
  modified: new Date(),
};

enum SubmissionStatus {
  Unsubmitted = "unsubmitted",
  Submitting = "submitting",
  Completed = "competed",
}

export default function CandidateUpdate() {
  const methods = useForm<ICandidate>({
    defaultValues: defaultValues,
    resolver: yupResolver(candidateSchema),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = methods;
  console.log(errors);

  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [candidate, setCandidate, candidateLoading] = useCandidate(id);

  const [submissionStatus, setSubmissionStatus] = React.useState(
    SubmissionStatus.Unsubmitted
  );

  const submitting = submissionStatus === SubmissionStatus.Submitting;

  const [dialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!candidateLoading) {
      reset(candidate!);
      document.title = candidate?.name || "Candidates";
    }
  }, [id, candidateLoading, candidate, reset]);

  React.useEffect(() => {
    if (isSubmitSuccessful && submissionStatus === SubmissionStatus.Completed) {
      setSubmissionStatus(SubmissionStatus.Unsubmitted);
      reset(candidate!);
    }
  }, [isSubmitSuccessful, submissionStatus, candidate, reset]);

  const handleUpdate: SubmitHandler<ICandidate> = (data) => {
    setSubmissionStatus(SubmissionStatus.Submitting);
    axios
      .patch<ICandidate>(`/candidates/${id}/`, data)
      .then((response) => {
        setCandidate(response.data);
        setSubmissionStatus(SubmissionStatus.Completed);
        enqueueSnackbar(`${response.data.name} updated.`, {
          variant: "success",
        });
      })
      .catch((error) => {
        setSubmissionStatus(SubmissionStatus.Unsubmitted);
        console.log(error);
      });
  };

  const handleDelete = () => {
    setSubmissionStatus(SubmissionStatus.Submitting);
    axios
      .delete(`/candidates/${id}/`)
      .then(() => {
        setSubmissionStatus(SubmissionStatus.Completed);
        enqueueSnackbar("Candidate deleted.", { variant: "warning" });
        setDialogOpen(false);
        navigate("/candidates");
      })
      .catch((error) => {
        setSubmissionStatus(SubmissionStatus.Unsubmitted);
        setDialogOpen(false);
        console.log(error);
      });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleUpdate)}
        autoComplete="off"
        sx={{
          display: "grid",
          columnGap: 3,
          ml: 3,
          mr: 3,
          gridTemplateRows: `auto 1fr`,
          gridTemplateColumns: `1fr 1fr 1fr 1fr`,
          gridTemplateAreas: `
                  "toolbar toolbar toolbar toolbar"
                  "candidate bio office notes"
                  `,
        }}
      >
        <Box
          sx={{
            gridArea: "toolbar",
            borderBottom: 1,
            borderTop: 1,
            borderColor: "grey.300",
          }}
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              CANDIDATE
            </Typography>
            <Stack spacing={2} direction="row">
              {!candidateLoading && candidate?.folder ? (
                <Button
                  href={candidate?.folder!}
                  target="_blank"
                  rel="noreferrer noopener"
                  startIcon={<FolderIcon />}
                >
                  Folder
                </Button>
              ) : null}
              <LoadingButton
                onClick={handleDialogOpen}
                startIcon={<DeleteIcon />}
                loading={submitting}
                loadingPosition="start"
                variant="text"
              >
                Delete
              </LoadingButton>
              <LoadingButton
                type="submit"
                startIcon={<SaveIcon />}
                loading={submitting}
                loadingPosition="start"
                variant="contained"
              >
                Update
              </LoadingButton>
            </Stack>
          </Toolbar>
          {candidateLoading ? <LinearProgress sx={{ mt: -0.5 }} /> : null}
        </Box>
        <Box sx={{ gridArea: "candidate" }}>
          <CandidateContactForm
            control={control}
            disabled={submitting}
            candidateLoading={candidateLoading}
          />
        </Box>
        <Box sx={{ gridArea: "bio" }}>
          <CandidateBioForm
            control={control}
            disabled={submitting}
            candidateLoading={candidateLoading}
          />
        </Box>
        <Box sx={{ gridArea: "office" }}>
          <CandidateOfficeForm
            control={control}
            disabled={submitting}
            candidateLoading={candidateLoading}
          />
        </Box>
        <Box sx={{ gridArea: "notes" }}>
          <NotesForm disabled={submitting} parentLoading={candidateLoading} />
        </Box>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Delete this candidate?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              onClick={handleDialogClose}
              loading={submitting}
              variant="text"
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
              loading={submitting}
              loadingPosition="start"
              variant="text"
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    </FormProvider>
  );
}
