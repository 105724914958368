import React from "react";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import {
  Link as RouterLink,
  LinkProps,
  useResolvedPath,
  useMatch,
} from "react-router-dom";

interface ListItemButtonLinkProps {
  icon: React.ReactNode;
  primary: string;
  to: string;
}

export default function ListItemButtonLink(props: ListItemButtonLinkProps) {
  const { icon, primary, to } = props;
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<LinkProps, "to">>(function Link(
        itemProps,
        ref
      ) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <li>
      <ListItemButton
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        component={renderLink}
        selected={Boolean(match)}
      >
        {icon}
        <Box sx={{ fontSize: "10px", lineHeight: "12px" }}>{primary}</Box>
      </ListItemButton>
    </li>
  );
}
